import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
import requestFile from '@/utils/request-file.js';

const { GET, POST } = reqMethodEnum;


// 实训管理 列表
export function GetTaskIndex(data) {
  return request({
    url: '/backend/web/task/index',
    method: GET,
    params:data
  });
}

// 实训状态改变
export function PostTaskState(data) {
  return request({
    url: '/backend/web/task/state',
    method: POST,
    data
  });
}

// 实训新增时的详情
export function GetTaskCreate(data) {
  return request({
    url: '/backend/web/task/create',
    method: GET,
    params:data
  });
}

// 实训详情
export function GetTaskDetail(data) {
  return request({
    url: '/backend/web/task/detail',
    method: GET,
    params:data
  });
}

// 实训删除
export function PostTaskDelete(data) {
  return request({
    url: '/backend/web/task/delete',
    method: POST,
    data
  });
}

// 实训新增
export function PostTaskCreate(data) {
  return request({
    url: '/backend/web/task/create',
    method: POST,
    data
  });
}

// 实训编辑
export function PostTaskUpdate(data) {
  return request({
    url: '/backend/web/task/update',
    method: POST,
    data
  });
}

// 实训分发
export function PostTaskDistribute(data) {
  return request({
    url: '/backend/web/task/distribute',
    method: POST,
    data
  });
}

// 教学系统列表
export function GetTeachingSystem(data) {
  return request({
    url: '/backend/web/task/teaching-system',
    method: GET,
    params:data
  });
}

// 实训导出架构
export function exportDir(data) {
  return requestFile({
    url: '/backend/web/task/export',
    method: POST,
    data,
    responseType: 'blob'
  });
}

// 获取实训已分发院校
export function PostWebTaskIndex(data) {
  return request({
    url: '/backend/web/task/index',
    method: POST,
    data
  });
}
